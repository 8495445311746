import React from "react"

import Layout from "../components/layout";

import "aos/dist/aos.css";

import NewsIntro from "@components/news-intro/news-intro";
import NewsWave from '@components/news-wave/news-wave';
import NewsContent from '@components/news-content/news-content';
import NewsOtherPost from "@components/news-other-post/news-other-post";
import ElfsightInstaWidgetModule from "../components/modules/ElfsightInstaWidgetModule/ElfsightInstaWidgetModule";

const NewsDetail = (props) => (
  <div className="news-detail">
    <Layout>
      <NewsIntro />
      <NewsWave />
      <NewsContent />
      <NewsOtherPost />
      <ElfsightInstaWidgetModule ModuleData = {props?.GlobalConfig?.Elfsight_Instagram_Widget?.Widget_Code} />
    </Layout>
  </div>
)

export default NewsDetail
